<template>
  <v-container>
    <!-- 演習時ヘッダー -->
    <v-app-bar app color="orange darken-1" dark dense height="40px">
      <font size="3"
        >第{{ perm.qNum + 1 }}問 / {{ perm.questionData.length }} ｜ 問題ID：{{
          cont.questionId
        }}
      </font>
      <v-spacer></v-spacer>
      <a @click="exit(true)">
        <div style="text-align: center">
          <v-icon large>mdi-exit-run</v-icon>
        </div>
      </a>
    </v-app-bar>
    <!-- 問題表示 -->
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="7">
        <div
          style="white-space: pre-wrap; word-wrap: break-word; margin-top: 12px"
        >
          <div v-html="cont.question" />
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="5">
        <span v-show="contBtn.showCheckBox"><CheckBox ref="CheckBox" /></span>
      </v-col>
    </v-row>
    <br />
    <br />
    <v-divider></v-divider>

    <!-- 正解と補足 -->
    <span v-show="contBtn.showAnswerAndComment">
      <AnswerAndComment ref="AnswerAndComment" />
    </span>

    <!-- ボタン操作 -->
    <!-- saveAndNextでは子compからisTrueを受け取ります -->
    <ContBtn
      ref="ContBtn"
      @fromContBtn_showComment="showComment"
      @fromContBtn_saveAndNext="saveAndNext"
    />
  </v-container>
</template>

<style>
.slide-base {
  max-width: 900px;
}
.slide {
  height: 0px;
  position: relative;
  width: 100%;
  overflow: hidden;
  /*padding-bottom: 50%;*/
  padding-top: 56.25%;
}
.slide iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
</style>

<script>
import { mapState } from 'vuex';
import CheckBox from '../compQuiz/CheckBox.vue';
import AnswerAndComment from '../compQuiz/AnswerAndComment.vue';
import ContBtn from '../compQuiz/ContBtn.vue';

export default {
  name: 'Quiz',
  components: { CheckBox, AnswerAndComment, ContBtn },
  computed: { ...mapState(['webApp', 'saveData', 'cont', 'contBtn', 'perm']) },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (vm.saveData.selKamokuName == '') {
        vm.$store.commit('leavePage', true);
        vm.$router.push('/home');
        return false;
      } else {
        vm.$store.commit('leavePage', false);
        vm.startTest();
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.webApp.allowLeavePage) {
      next();
    } else {
      //exitする場合は、allowLeavePage = true にしてからrouter操作をするので、2週目でnext()できる
      this.exit(); //ブラウザバックをしている状況なので、引数なし
    }
  },
  created() {
    window.addEventListener('beforeunload', this.handler);
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.handler);
  },
  mounted() {
    this.$store.commit('mainHeader', false);
  },
  methods: {
    //問題制御
    //======================================================================================
    async startTest() {
      //saveDataの初期化は、homeにてselSectionIdを保管するタイミングで実行済み
      //問題作成
      await this.contQ_createMain();
      await this.contQ_setQuestionMain();

      //問題開始時刻の監視、終了時刻はcontorolQuizのfinish()で計測
      this.$store.commit('update_store', {
        path: 'saveData',
        key: 'startTime',
        data: new Date().getTime(),
      });
    },
    showComment() {
      //解答表示が終わり、judgeが済んだタイミング。ContBtnから伝令あり。
      const kamokuName = this.saveData.selKamokuName;

      const Q = this.perm.questionData;
      const ind = this.perm.qNum;
      const ele = Q[ind];

      const data = {
        selKamokuName: kamokuName,
        question: ele,
      };
      this.$refs.AnswerAndComment.setContents(data); //検索時との兼ね合いで、渡すデータが多くなってます。
      this.$store.commit('update_store', {
        path: 'contBtn',
        key: 'btnAllUncheck',
        data: true,
      }); //全件解除ボタンを停止
      this.contBtn.showAnswerAndComment = true; //v-ifにするとtrueになるまでrefを実行できないので、v-showにしています
    },
    saveAndNext(isCorrect) {
      //子コンポから一度受け取る。showQuestionではこのタイミングで停止することでエラーを避けている。
      this.contQ_saveAndNext(isCorrect);
    },
    handler(event) {
      event.preventDefault();
      event.returnValue = '';
    },
    exit(isExit) {
      //問題からの離脱、ボタンならtrue、ブラウザバックならundifinedとなる
      const kamokuName = this.saveData.selKamokuName;
      const isAssignment = this.$store.state.perm.isAssignment;
      if (isAssignment) {
        //課題中の離脱
        this.exitAssignment(kamokuName);
        return;
      }
      if (this.perm.qNum == 0) {
        //解答済み問題数が0の場合
        var res = window.confirm('問題選択ページに戻ってよろしいですか？');
        if (res) {
          this.$store.commit('update_store', {
            path: 'webApp',
            key: 'allowLeavePage',
            data: true,
          });
          this.$router.push('/' + kamokuName);
        } else {
          return;
        }
      } else {
        this.exitMidstream(isExit, kamokuName);
      }
    },
    exitAssignment(kamokuName) {
      var res = window.confirm('⚠ 課題を中止します。よろしいですか？');
      if (res) {
        this.$store.commit('update_store', {
          path: 'webApp',
          key: 'allowLeavePage',
          data: true,
        });
        this.$router.push('/' + kamokuName);
      } else {
        return;
      }
    },
    exitMidstream(isExit, kamokuName) {
      if (isExit == undefined) {
        //ブラウザバックの場合undefinedになる
        let res = window.confirm(
          '⚠ このまま戻ると取組内容は記録されません。\r\n問題選択ページに戻ってよろしいですか？\r\n（※ここまでの学習記録を残す場合は、右上のボタンから戻ってください。）',
        );
        if (res) {
          this.$store.commit('update_store', {
            path: 'webApp',
            key: 'allowLeavePage',
            data: true,
          });
          this.$router.push('/' + kamokuName);
        } else {
          return;
        }
      } else {
        //exitボタンの場合こっちになる
        const res = window.confirm(
          'ここまでの結果を表示します。よろしいですか？',
        );
        if (!res) {
          return;
        }
        this.contQ_finish();
      }
    },
  },
};
</script>
